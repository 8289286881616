<template>
  <div class="content">
    <div class="holder holder--between holder--indent">
      <div class="content__party">
        <router-link
          :to="{ name: 'partner-address' }"
          class="button button--light button--no-indent button--small"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
          </svg>
        </router-link>
        <h1 class="title title--big title--color">Редактирование адреса (ID: {{ this.$route.params.id }})</h1>
      </div>
      <button @click="deleteDiscount($route.params.id)" class="button button--light button--no-indent button--circle">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Удалить адрес</span>
      </button>
    </div>
    <form class="form" @submit.prevent="checkForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group" :class="{'form-group--error': $v.form.address.$error}">
            <label>Поиск адреса<sup>*</sup></label>
            <v-select
              @input="setValues"
              label="address"
              v-model="form.address"
              :filterable="false"
              :options="addressOptions"
              @search="onAddressSearch"
              :get-option-label="getLabel"
              placeholder="Введите свой адрес"
              class="select"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">введите для поиска свой адрес..</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.address }}</div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.address.$dirty && !$v.form.address.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="region">Регион</label>
            <div class="form-control">
              <input
                id="region"
                type="text"
                name="region"
                class="form-control__input"
                v-model.trim="form.region"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="district">Район</label>
            <div class="form-control">
              <input
                id="district"
                type="text"
                name="district"
                class="form-control__input"
                v-model.trim="form.district"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="city">Город / н.п.</label>
            <div class="form-control">
              <input
                id="city"
                type="text"
                name="city"
                class="form-control__input"
                v-model.trim="form.city"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="street">Улица</label>
            <div class="form-control">
              <input
                id="street"
                type="text"
                name="street"
                class="form-control__input"
                v-model.trim="form.street"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="house">Дом</label>
            <div class="form-control">
              <input
                id="house"
                type="text"
                name="house"
                class="form-control__input"
                placeholder="Введите номер дома"
                v-model.trim="form.house"
              />
            </div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label for="post_index">Индекс</label>
            <div class="form-control">
              <input
                id="post_index"
                type="text"
                name="post_index"
                class="form-control__input"
                v-model.trim="form.post_index"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="floor">Этаж</label>
            <div class="form-control">
              <input
                id="floor"
                type="text"
                name="floor"
                class="form-control__input"
                placeholder="Введите номер этажа"
                v-model.trim="form.floor"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="office">Офис</label>
            <div class="form-control">
              <input
                id="office"
                type="text"
                name="office"
                class="form-control__input"
                placeholder="Введите номер офиса"
                v-model.trim="form.office"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="latitude">Координаты: Широта<sup>*</sup></label>
            <div class="form-control" :class="{'form-control--error': $v.form.geo_lat.$error}">
              <input
                id="latitude"
                type="text"
                name="latitude"
                class="form-control__input"
                placeholder="Введите широту"
                v-model.trim="form.geo_lat"
                readonly
              />
            </div>
            <div class="form-group__error" v-if="$v.form.geo_lat.$dirty && !$v.form.geo_lat.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="longitude">Координаты: Долгота<sup>*</sup></label>
            <div class="form-control" :class="{'form-control--error': $v.form.geo_lon.$error}">
              <input
                id="longitude"
                type="text"
                name="longitude"
                class="form-control__input"
                placeholder="Введите долготу"
                v-model.trim="form.geo_lon"
                readonly
              />
            </div>
            <div class="form-group__error" v-if="$v.form.geo_lon.$dirty && !$v.form.geo_lon.required">Обязательное поле</div>
          </div>
<!--          <div class="form-group" v-if="form.geo_lon && form.geo_lat">-->
<!--            <p>Карта</p>-->
<!--          </div>-->
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" ref="submit">
          <span>Сохранить изменения</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import {required} from 'vuelidate/lib/validators';
import {validationMixin} from 'vuelidate';

export default {
  mixins: [validationMixin],
  validations: {
    form: {
      address: {required},
      geo_lat: {required},
      geo_lon: {required},
    }
  },
  data() {
    return {
      form: {
        address: '',
        region: '',
        district: '',
        city: '',
        street: '',
        house: '',
        post_index: '',
        floor: '',
        office: '',
        geo_lat: '',
        geo_lon: '',
      },
      addressOptions: [],
    };
  },
  mounted() {
    this.fetchAddress();
  },
  methods: {
    fetchAddress() {
      this.$store.dispatch('partner/address/GET_DATA', this.$route.params.id)
        .then(response => {
          if (response.status >= 400) {
            this.$notify({
              type: 'warn',
              title: 'Внимание!',
              text: 'Ошибка получения данных'
            });
          }
          this.form = response.data;
          this.form.address = response.data.full_address;
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: error
          });
        });
    },
    checkForm() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.sendForm();
      }
    },
    sendForm() {
      const data = {
        ...this.form,
        address: this.form.address.address ?? this.form.address,
      };
      this.$refs.submit.classList.add('preload');
      this.$store.dispatch('partner/address/PATCH_DATA', {id: this.$route.params.id, data})
        .then(() => {
          this.$refs.submit.classList.remove('preload');
            this.$notify({
              type: 'success',
              title: 'Уведомление',
              text: 'Данные успешно сохранены'
            });
            this.$router.push({name: 'partner-address'});
        })
        .catch(response => {
          this.$refs.submit.classList.remove('preload');
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    onAddressSearch (search, loading) {
      loading(true);
      this.searchDelivery(loading, search, this);
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.$store.dispatch('partner/address/GET_DADATA', {search}).then(response => {
        vm.addressOptions = response.data;
        loading(false);
      });
    }, 350),
    setValues() {
      this.form.region = this.form.address?.region;
      this.form.district = this.form.address?.district;
      this.form.city = this.form.address?.city;
      this.form.street = this.form.address?.street;
      this.form.house = this.form.address?.house;
      this.form.post_index = this.form.address?.post_index;
      this.form.geo_lat = this.form.address?.geo_lat;
      this.form.geo_lon = this.form.address?.geo_lon;
    },
    getLabel (option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    deleteDiscount(id) {
      this.$store.dispatch('partner/address/DELETE_DATA', id)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Адрес успешно удален'
          });
          this.$router.push({ name: 'partner-address' });
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Внимание!',
            text: 'Ошибка удаления адреса'
          });
        });
    }
  },
};
</script>
